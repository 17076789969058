$black: #333333;
$gray: #7e7e7e;

$secondary-color: #F38400;
$secondary-color-darker: #cc7000;

$primary-color: #875692;
$primary-color-darker: #5d3466;

.secondary-color {
    color: $secondary-color;
}